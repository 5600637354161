import { protectEmail } from "./email-protection.js";

(function () {
  // Scroll-based animations
  const animateOnScroll = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("animate");
      } else {
        entry.target.classList.remove("animate");
      }
    });
  });

  document.querySelectorAll(".animate-on-scroll").forEach((element) => {
    animateOnScroll.observe(element);
  });

  // Hamburger menu
  document.querySelectorAll(".hamburger").forEach((element) => {
    element.addEventListener("click", () => {
      element.classList.toggle("hamburger__active");
    });
  });

  // Navigation
  const currentPage = document.location.pathname.split("/")[1];
  const navLinks = document.querySelectorAll(
    ".nav__list > .nav__item > .nav__link"
  );
  [...navLinks]
    .find((el) => {
      const pathParts = el.href.split("/");

      return (
        currentPage === pathParts[pathParts.length - 1] ||
        (currentPage === "" && pathParts[pathParts.length - 1] === "index.html")
      );
    })
    ?.classList.add("nav__link--active");

  protectEmail();
})();
