function protectEmail() {
  document.querySelectorAll("[data-email]").forEach((element) => {
    const encodedEmail = element.dataset.email;

    element.addEventListener("click", (e) => {
      e.preventDefault();
      const decodedEmail = decodeEmail(encodedEmail);
      window.location.href = `mailto:${decodedEmail}`;
    });

    element.setAttribute("title", `Email: ${encodedEmail}`);
    if (element.textContent === "#") {
      element.textContent = decodeEmail(encodedEmail);
    }
  });
}

function decodeEmail(email) {
  return email.replace("[at]", "@").replace(/\[dot\]/g, ".");
}

export { protectEmail };
